'use client';

import {Button, Empty} from '@job-ish/ui/components';
import {IconError404} from '@tabler/icons-react';
import {useRouter} from 'next/navigation';

const NotFound = () => {
	const {push} = useRouter();

	return (
		<Empty
			className="grow"
			icon={IconError404}
			subtitle="Sorry, the page you requested could not be found."
			title="Page not found"
		>
			<Button color="primary" intent="outline" onPress={() => push('/')}>
				Go to Job Board
			</Button>
		</Empty>
	);
};

export default NotFound;
